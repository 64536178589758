import { RepairAssignedToMixtureListItemDto } from '../../dtos/repair-assigned-to-mixture-list-item/repair-assigned-to-mixture-list-item.dto';
import { RepairAssignedToMixtureListItemModel } from '../../models/repair-assigned-to-mixture-list-item/repair-assigned-to-mixture-list-item.model';

export class RepairAssignedToMixtureListItemCreator {
  public static create(dto: RepairAssignedToMixtureListItemDto): RepairAssignedToMixtureListItemModel {
    return {
      amount: dto.amount,
      brand: dto.brand,
      isTestSpraying: dto.test_spraying,
      model: dto.model,
      producerColor: dto.producer_color,
      registrationNumber: dto.registration_number,
      repairNumber: dto.repair_number,
      repairId: dto.repair_id,
    };
  }
}
