import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, from, of, switchMap, catchError } from 'rxjs';
import { take } from 'rxjs/operators';

import { AddColorIssueFormModel } from '../../../color-issues/models/add-color-issue-form/add-color-issue-form.model';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { CanDeactivateGuardModel } from '../../../shared/models/can-deactivate-guard/can-deactivate-guard.model';
import { LocationGetStateUtil } from '../../../shared/utils/location-get-state/location-get-state.util';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeDetailsModalService } from '../recipe-details-modal/recipe-details-modal.service';

@Injectable({
  providedIn: 'root',
})
export class RecipeDetailsNavigationService {
  constructor(private readonly router: Router, private readonly recipeDetailsModalService: RecipeDetailsModalService) {}

  public navigateToPrepareMixturePage(
    recipeType: RecipeTypeModel,
    colorId: number,
    formulaId: number,
    recipeId: string,
    measurementId?: string,
    repairId?: string,
    searchFiltersSignature?: string,
    mixtureId?: string
  ): Observable<boolean> {
    return this.recipeDetailsModalService
      .getRecipeDetailsToPrepareMixture(
        recipeType,
        colorId,
        formulaId,
        recipeId,
        measurementId,
        repairId,
        searchFiltersSignature,
        mixtureId
      )
      .pipe(
        switchMap((prepareMixtureState: PrepareMixtureStateModel) => {
          const locationState: CanDeactivateGuardModel<PrepareMixtureStateModel> = {
            canDeactivate: true,
            data: prepareMixtureState,
          };

          return from(this.router.navigate(['/mixes/prepare-mixture'], { state: LocationGetStateUtil.createState(locationState) }));
        }),
        catchError(() => of(false))
      );
  }

  public createStateAndNavigateToAddColorIssue(
    recipeDetails: RecipeDetailsModalParamsModel | null,
    colorSystemId: number | null,
    defaultColorboxKey: number | null
  ): Observable<boolean> {
    if (!recipeDetails || !colorSystemId) {
      return of(false);
    }

    const addColorIssueDetails: Partial<AddColorIssueFormModel> = {
      colorDetails: {
        alternative: recipeDetails.alternativeDescription || '',
        colorSystemId: colorSystemId,
        colorbox: defaultColorboxKey,
        flashcard: null,
        manufacturer: recipeDetails.brand || '',
        code: recipeDetails.producerColorCode || '',
        name: recipeDetails.producerColor || '',
        amount: null,
      },
    };

    return this.navigateToAddColorIssue(addColorIssueDetails);
  }

  public createStateFromDetailsAndNavigateToAddColorIssue(
    details: RecipeFormulaDetailsModel | null,
    defaultColorboxKey: number | null
  ): Observable<boolean> {
    if (!details) {
      return of(false);
    }

    const addColorIssueDetails: Partial<AddColorIssueFormModel> = {
      colorDetails: {
        alternative: details.alternativeDescription,
        colorSystemId: details.additionalData?.colorSystemId || null,
        colorbox: details.additionalData?.colorboxId || defaultColorboxKey,
        flashcard: details.additionalData?.flashcard || null,
        manufacturer: details.brand,
        code: details.producerColorCode,
        name: details.producerColorName,
        amount: null,
      },
    };

    return this.navigateToAddColorIssue(addColorIssueDetails);
  }

  private navigateToAddColorIssue(addColorIssueDetails: Partial<AddColorIssueFormModel>): Observable<boolean> {
    return from(this.router.navigate(['/color-issues'], { state: LocationGetStateUtil.createState(addColorIssueDetails) })).pipe(take(1));
  }
}
