<app-modal [hasContentSidePadding]="false" [hasContentVerticalPadding]="false" class="recipe-details-modal">
  <ng-container modal-content>
    <div class="recipe-details-container">
      <div class="recipe-details-header">
        <div class="recipe-details-header-up">
          <app-recipe-details-tabs
            [isColorSystemFormValid]="colorSystemFormControl.valid"
            [measurementId]="measurementId"
            [tabs]="tabs"
            (tabSelected)="onTabButtonClick($event)"
          ></app-recipe-details-tabs>
        </div>

        <div class="recipe-details-header-down">
          <div class="toggle-box-container" *ngIf="isShownRecipeTypeToggle">
            <app-form-control-toggle-box
              class="form-control recipe-type-options"
              [toggleBoxOptions]="recipeTypeOptions"
              [formControl]="recipeTypeForm"
            ></app-form-control-toggle-box>
          </div>

          <app-recipe-details-color-system-selector
            [formulaColorOptions]="formulaColorOptions"
            [isDisabled]="isColorSystemFormDisabled"
            [formControl]="colorSystemFormControl"
          ></app-recipe-details-color-system-selector>

          <div class="buttons">
            <app-recipe-details-add-color-issue-button
              [isDisabled]="!colorSystemFormControl.value"
              (addColorIssueClicked)="addColorIssue()"
            ></app-recipe-details-add-color-issue-button>

            <ng-container *ngIf="selectedTab?.id !== selectedTabOptions.linkedColor">
              <app-recipe-details-mix-button
                [isDisabled]="!colorSystemFormControl.value"
                (mixButtonClicked)="goToPrepareMixturePage()"
              ></app-recipe-details-mix-button>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="recipe-details-content" [class.no-scrollbar]="selectedTab?.id === selectedTabOptions.preview">
        <ng-template #showError>
          <ng-container *ngIf="isTabDataError$ | async">
            <app-unexpected-error class="unexpected-error" (buttonClicked)="tryLoadDataAgain()"></app-unexpected-error>
          </ng-container>
        </ng-template>

        <ng-scrollbar class="modal-scrollbar" [autoHeightDisabled]="false">
          <ng-container
            *ngIf="colorSystemFormControl.value"
            [ngTemplateOutlet]="showNoLinkedColorsWarning"
            [ngTemplateOutletContext]="{ $implicit: noLinkedColorsWarning }"
          ></ng-container>

          <ng-template #showNoLinkedColorsWarning let-options let-showBorder="showBorder">
            <ng-container *ngIf="isTabDataError$ | async">
              <div class="no-recipe" [class.border]="showBorder">
                <app-no-results [noResultsOptions]="options"></app-no-results>
              </div>
            </ng-container>
          </ng-template>

          <ng-container
            *ngIf="colorSystemFormControl.invalid && (isTabDataError$ | async) === false"
            [ngTemplateOutlet]="showWarning"
            [ngTemplateOutletContext]="{ $implicit: noColorSystemSelectedWarning }"
          ></ng-container>

          <ng-template #showWarning let-options let-showBorder="showBorder">
            <div class="no-recipe" [class.border]="showBorder">
              <app-no-results [noResultsOptions]="options"></app-no-results>
            </div>
          </ng-template>

          <app-loader *ngIf="colorSystemFormControl.valid && isContentLoading; else isLoaded"></app-loader>

          <ng-template #isLoaded>
            <ng-container *ngIf="colorSystemFormControl.valid" [ngSwitch]="selectedTab?.id">
              <ng-container *ngSwitchCase="selectedTabOptions.info">
                <app-recipe-details-info
                  [details]="details"
                  [showLinkedColorAlert]="!!recipeDetails?.linkedColor?.isLinkedColor"
                  *ngIf="recipeFormulaDetails$ | async as details; else showError"
                ></app-recipe-details-info>
              </ng-container>

              <ng-container *ngSwitchCase="selectedTabOptions.prices">
                <app-recipe-details-prices
                  [recipePriceForm]="recipePriceForm"
                  [recipePriceLists]="recipePriceLists"
                  (recipePriceFormValueChanged)="recipePriceFormValueChange($event)"
                  *ngIf="recipePriceLists$ | async as recipePriceLists; else showError"
                ></app-recipe-details-prices>
              </ng-container>

              <ng-container *ngSwitchCase="selectedTabOptions.ingredients">
                <app-recipe-details-ingredients
                  [formulaIngredients]="ingredients"
                  [isCorrection]="isCorrection"
                  *ngIf="recipeFormulaIngredients$ | async as ingredients; else showError"
                ></app-recipe-details-ingredients>
              </ng-container>

              <ng-container *ngSwitchCase="selectedTabOptions.chart">
                <div class="recipe-details-spectral-chart">
                  <app-recipe-details-spectral-chart
                    [spectralChartData]="spectralChartData"
                    (geometriesChanged)="setSpectralChartGeometries($event)"
                    *ngIf="recipeFormulaSpectralChart$ | async as spectralChartData; else showError"
                  ></app-recipe-details-spectral-chart>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="selectedTabOptions.preview">
                <app-recipe-details-angles
                  [anglesData]="angles"
                  (geometriesChanged)="setAnglesGeometries($event)"
                  *ngIf="recipeFormulaAngles$ | async as angles; else showError"
                ></app-recipe-details-angles>
              </ng-container>

              <ng-container *ngSwitchCase="selectedTabOptions.linkedColor">
                <ng-container *ngIf="(isTabDataError$ | async) === false">
                  <app-recipe-linked-colors
                    [linkedColorsDetails]="linkedColorsDetails"
                    [recipeId]="recipeDetails?.id"
                    [repairId]="repairId"
                    [colorSystemId]="colorSystemFormControl.value"
                    *ngIf="linkedColorsDetails$ | async as linkedColorsDetails; else showNoLinkedColorsWarning"
                  ></app-recipe-linked-colors>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>
        </ng-scrollbar>
      </div>
    </div>
  </ng-container>

  <ng-container modal-footer>
    <div class="modal-footer-buttons">
      <ng-container *ngIf="colorSystemFormControl.valid && selectedTab?.id === selectedTabOptions.linkedColor">
        <app-export-linked-colors-to-pdf
          [id]="$any(linkedColorsDetails$ | async)?.id"
          [isDisabled]="isContentLoading || (isTabDataError$ | async) || !$any(linkedColorsDetails$ | async)?.colors?.length"
        ></app-export-linked-colors-to-pdf>
      </ng-container>

      <button class="button red" (click)="close()">{{ 'recipes.recipeDetailsModal.buttons.closeModal' | translate }}</button>
    </div>
  </ng-container>
</app-modal>
