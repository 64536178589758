import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { DropdownOptionModel } from '../../../forms/models/dropdown-option/dropdown-option.model';
import { RecipeFormulaBasicModel } from '../../models/recipe-formula-basic/recipe-formula-basic.model';
import { CurrentSettingsService } from '../../../settings/services/current-settings/current-settings.service';
import { LinkedColorsDetailsModel } from '../../../linked-colors/models/linked-colors-details/linked-colors-details.model';
import { CombinationDetailsModel } from '../../../color-combinations/models/combination-details/combination-details.model';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeDetailsModalStateModel } from '../../models/recipe-details-modal-state/recipe-details-modal-state.model';
import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';

@Injectable({
  providedIn: 'root',
})
export class RecipeDetailsStateService {
  private readonly recipeFormulaDetails$: BehaviorSubject<RecipeFormulaDetailsModel | null>;
  private readonly recipeFormulaIngredients$: BehaviorSubject<Array<RecipeFormulaIngredientsModel>>;
  private readonly recipeFormulaSpectralChart$: BehaviorSubject<RecipeDetailsSpectralChartDataModel | null>;
  private readonly recipeFormulaAngles$: BehaviorSubject<RecipeDetailsAnglesDataModel | null>;
  private readonly recipeDetails$: BehaviorSubject<RecipeDetailsModalParamsModel | null>;
  private readonly formulaId$: BehaviorSubject<number>;
  private readonly colorId$: BehaviorSubject<number>;
  private readonly formulaColorOptions$: BehaviorSubject<Array<DropdownOptionModel<number>>>;
  private readonly isColorSystemFormDisabled$: BehaviorSubject<boolean>;
  private readonly linkedColorsDetailsSubject$: BehaviorSubject<LinkedColorsDetailsModel | null>;
  private readonly colorCombinationDetails$: BehaviorSubject<CombinationDetailsModel | null>;
  private readonly recipeFormulaPricesSubject$: BehaviorSubject<Array<RecipePriceModel>>;
  private readonly isTabDataError$: BehaviorSubject<boolean>;
  private readonly userColorSystem$: BehaviorSubject<string>;

  constructor(private readonly currentSettingsService: CurrentSettingsService) {
    this.recipeFormulaPricesSubject$ = new BehaviorSubject<Array<RecipePriceModel>>([]);
    this.recipeDetails$ = new BehaviorSubject<RecipeDetailsModalParamsModel | null>(null);
    this.formulaId$ = new BehaviorSubject<number>(0);
    this.colorId$ = new BehaviorSubject<number>(0);
    this.formulaColorOptions$ = new BehaviorSubject<Array<DropdownOptionModel<number>>>([]);
    this.isColorSystemFormDisabled$ = new BehaviorSubject<boolean>(false);
    this.linkedColorsDetailsSubject$ = new BehaviorSubject<LinkedColorsDetailsModel | null>(null);
    this.colorCombinationDetails$ = new BehaviorSubject<CombinationDetailsModel | null>(null);
    this.recipeFormulaDetails$ = new BehaviorSubject<RecipeFormulaDetailsModel | null>(null);
    this.recipeFormulaIngredients$ = new BehaviorSubject<Array<RecipeFormulaIngredientsModel>>([]);
    this.recipeFormulaSpectralChart$ = new BehaviorSubject<RecipeDetailsSpectralChartDataModel | null>(null);
    this.recipeFormulaAngles$ = new BehaviorSubject<RecipeDetailsAnglesDataModel | null>(null);
    this.isTabDataError$ = new BehaviorSubject<boolean>(false);
    this.userColorSystem$ = new BehaviorSubject<string>('');
  }

  public updateRecipeState(state: RecipeDetailsModalStateModel): void {
    if (this.recipeDetails$.value === state?.recipeDetails) {
      return;
    }

    this.recipeDetails$.next(state.recipeDetails || null);
    this.colorId$.next(state.colorId || 0);
    this.formulaId$.next(state.formulaId || 0);
  }

  public setFormulaId(id: number): void {
    this.formulaId$.next(id);
  }

  public getFormulaId(): Observable<number> {
    return this.formulaId$.asObservable();
  }

  public setColorId(id: number): void {
    this.colorId$.next(id);
  }

  public getColorId(): Observable<number> {
    return this.colorId$.asObservable();
  }

  public getFormulaColorId(formulas?: Array<RecipeFormulaBasicModel>, colorSystemId?: number): number {
    if (!formulas?.length || !colorSystemId) {
      return 0;
    }

    return formulas.find((formula: RecipeFormulaBasicModel) => formula.colorSystemId === colorSystemId)?.id || 0;
  }

  public getDefaultColorboxKey(): number {
    return Number(this.currentSettingsService.getDefaultColorboxKey());
  }

  public setColorCombinationDetails(details: CombinationDetailsModel): void {
    this.colorCombinationDetails$.next(details);
  }

  public getColorCombinationDetails(): Observable<CombinationDetailsModel | null> {
    return this.colorCombinationDetails$.asObservable();
  }

  public setRecipeFormulaPrices(prices: Array<RecipePriceModel>): void {
    this.recipeFormulaPricesSubject$.next(prices);
  }

  public getRecipeFormulaPrices(): Observable<Array<RecipePriceModel>> {
    return this.recipeFormulaPricesSubject$.asObservable();
  }

  public setRecipeFormulaDetails(details: RecipeFormulaDetailsModel | null): void {
    this.recipeFormulaDetails$.next(details);
  }

  public getRecipeFormulaDetails(): Observable<RecipeFormulaDetailsModel | null> {
    return this.recipeFormulaDetails$.asObservable();
  }

  public setRecipeFormulaIngredients(ingredients: Array<RecipeFormulaIngredientsModel>): void {
    this.recipeFormulaIngredients$.next(ingredients);
  }

  public getRecipeFormulaIngredients(): Observable<Array<RecipeFormulaIngredientsModel>> {
    return this.recipeFormulaIngredients$.asObservable();
  }

  public setRecipeFormulaSpectralChartData(data: RecipeDetailsSpectralChartDataModel | null): void {
    this.recipeFormulaSpectralChart$.next(data);
  }

  public getRecipeFormulaSpectralChartData(): Observable<RecipeDetailsSpectralChartDataModel | null> {
    return this.recipeFormulaSpectralChart$.asObservable();
  }

  public setRecipeFormulaAnglesData(data: RecipeDetailsAnglesDataModel | null): void {
    this.recipeFormulaAngles$.next(data);
  }

  public getRecipeFormulaAnglesData(): Observable<RecipeDetailsAnglesDataModel | null> {
    return this.recipeFormulaAngles$.asObservable();
  }

  public setLinkedColorsDetails(details: LinkedColorsDetailsModel | null): void {
    this.linkedColorsDetailsSubject$.next(details);
  }

  public getLinkedColorsDetails(): Observable<LinkedColorsDetailsModel | null> {
    return this.linkedColorsDetailsSubject$.asObservable();
  }

  public setIsTabDataError(isError: boolean): void {
    this.isTabDataError$.next(isError);
  }

  public getIsTabDataError(): Observable<boolean> {
    return this.isTabDataError$.asObservable();
  }

  public getUserColorSystem(): string {
    return this.userColorSystem$.getValue();
  }

  public setUserColorSystem(colorSystem: string): void {
    this.userColorSystem$.next(colorSystem);
  }

  public resetState(): void {
    this.formulaId$.next(0);
    this.colorId$.next(0);
    this.formulaColorOptions$.next([]);
    this.isColorSystemFormDisabled$.next(false);
    this.recipeDetails$.next(null);
    this.recipeFormulaDetails$.next(null);
    this.recipeFormulaIngredients$.next([]);
    this.recipeFormulaSpectralChart$.next(null);
    this.recipeFormulaAngles$.next(null);
    this.linkedColorsDetailsSubject$.next(null);
    this.colorCombinationDetails$.next(null);
    this.recipeFormulaPricesSubject$.next([]);
    this.userColorSystem$.next('');
    this.isTabDataError$.next(false);
  }
}
