import { MixtureRecipeHistoryDto } from '../../dtos/mixture-recipe-history/mixture-recipe-history.dto';
import { MixtureRecipeSourceDetailsDto } from '../../dtos/mixture-recipe-source-details/mixture-recipe-source-details.dto';
import { MixtureRecipeHistoryModel } from '../../models/mixture-recipe-history/mixture-recipe-history.model';
import { MixtureRecipeSourceDetailsModel } from '../../models/mixture-recipe-source-details/mixture-recipe-source-details.model';
import { MixtureRecipeHistoryCreator } from '../mixture-recipe-history/mixture-recipe-history.creator';

export class MixtureRecipeSourceDetailsCreator {
  public static create(dto: MixtureRecipeSourceDetailsDto): MixtureRecipeSourceDetailsModel {
    return {
      additionalInformation: dto.additional_information,
      alternativeDescription: dto.alternative_description,
      brand: dto.brand,
      colorSystem: dto.color_system,
      colorboxes: dto.color_boxes,
      effect: dto.effect,
      effectThickness: dto.effect_thickness,
      primer: {
        primer: dto.primer.primer,
        hexColor: dto.primer.hex_color,
      },
      producerColorCode: dto.producer_color_code,
      producerColorName: dto.producer_color_name,
      productionYear: dto.production_year,
      recipeDate: dto.recipe_date,
      recipeHistory: MixtureRecipeSourceDetailsCreator.createRecipeHistoryList(dto.recipe_history),
    };
  }

  private static createRecipeHistoryList(recipeHistoryList: Array<MixtureRecipeHistoryDto>): Array<MixtureRecipeHistoryModel> {
    return recipeHistoryList.map((dto: MixtureRecipeHistoryDto) => MixtureRecipeHistoryCreator.create(dto));
  }
}
