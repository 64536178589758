import { MixtureMeasurementListDataPageDto } from '../../dtos/mixture-measurement-list-data-page/mixture-measurement-list-data-page.dto';
import { MixtureMeasurementListDataPageModel } from '../../models/mixture-measurement-list-data-page/mixture-measurement-list-data-page.model';

export class MixtureMeasurementListDataPageDtoCreator {
  public static create(model: MixtureMeasurementListDataPageModel): MixtureMeasurementListDataPageDto {
    return {
      ...(model.name && { name: model.name }),
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      page: model.page,
    };
  }
}
