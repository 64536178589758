import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { CustomHttpParamEncoder } from '../../../core/encoders/custom-http-params-encoder/custom-http-params.encoder';
import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { MixtureListItemDto } from '../../dtos/mixture-list-item/mixture-list-item.dto';
import { EditMixtureFormDto } from '../../dtos/edit-mixture-form/edit-mixture-form.dto';
import { AddMixtureFormDto } from '../../dtos/add-mixture-form/add-mixture-form.dto';
import { AssignMixtureToRepairsDto } from '../../dtos/assign-mixture-to-repairs/assign-mixture-to-repairs.dto';
import { MixtureDetailsDto } from '../../dtos/mixture-details/mixture-details.dto';
import { AssignMixtureToRepairsDataPageDto } from '../../dtos/assign-mixture-to-repairs-data-page/assign-mixture-to-repairs-data-page.dto';
import { RepairAssignedToMixtureListDto } from '../../dtos/repair-assigned-to-mixture-list/repair-assigned-to-mixture-list.dto';
import { MixtureLayersWithIngredientsAndConfigurationsDto } from '../../dtos/mixture-layers-with-ingredients-and-configurations/mixture-layers-with-ingredients-and-configurations.dto';
import { PrepareMixtureConfigurationsFiltersDto } from '../../dtos/prepare-mixture-configurations-filters/prepare-mixture-configurations-filters.dto';
import { MixtureSourceDetailsDto } from '../../dtos/mixture-source-details/mixture-source-details.dto';
import { MixtureFormulaIdFormDto } from '../../dtos/mixture-formula-id-form/mixture-formula-id-form.dto';
import { MixtureAmountAndMethodFormDto } from '../../dtos/mixture-amount-and-method-form/mixture-amount-and-method-form.dto';
import { RepairAssignedToMixtureDto } from '../../dtos/repair-assigned-to-mixture/repair-assigned-to-mixture.dto';
import { MixturePricesFiltersDto } from '../../dtos/mixture-prices-filters/mixture-prices-filters.dto';
import { MixturePricesDto } from '../../dtos/mixture-prices/mixture-prices.dto';
import { MixtureRecipeHistoryLayerDetailsDto } from '../../dtos/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.dto';
import { MixtureListDataPageDto } from '../../dtos/mixture-list-data-page/mixture-list-data-page.dto';
import { MixtureMeasurementListItemDto } from '../../dtos/mixture-measurement-list-item/mixture-measurement-list-item.dto';
import { MixtureMeasurementListDataPageDto } from '../../dtos/mixture-measurement-list-data-page/mixture-measurement-list-data-page.dto';

@Injectable({
  providedIn: 'root',
})
export class MixesHttpService {
  constructor(private httpClient: HttpClient) {}

  public getMixtureList(mixtureListDataPageDto: MixtureListDataPageDto): Observable<ListDto<MixtureListItemDto>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...mixtureListDataPageDto },
    });

    return this.httpClient
      .get<ApiResponseDto<ListDto<MixtureListItemDto>>>(ApiUrlUtil.getApiUrl('mixture/mixtures'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<MixtureListItemDto>>) => response.data));
  }

  public editMixture(id: string, editMixtureDataDto: EditMixtureFormDto): Observable<void> {
    return this.httpClient.put<void>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${id}`), editMixtureDataDto);
  }

  public addMixture(addMixtureDataDto: AddMixtureFormDto): Observable<void> {
    return this.httpClient.post<void>(ApiUrlUtil.getApiUrl('mixture/mixtures'), addMixtureDataDto);
  }

  public getMixtureDetails(mixtureId: string): Observable<MixtureDetailsDto> {
    return this.httpClient
      .get<ApiResponseDto<MixtureDetailsDto>>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}`))
      .pipe(map((response: ApiResponseDto<MixtureDetailsDto>) => response.data));
  }

  public getRepairAssignedToMixture(id: string): Observable<RepairAssignedToMixtureDto> {
    return this.httpClient
      .get<ApiResponseDto<RepairAssignedToMixtureDto>>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${id}/repair`))
      .pipe(map((response: ApiResponseDto<RepairAssignedToMixtureDto>) => response.data));
  }

  public getRepairsAssignedToMixture(
    mixtureId: string,
    assignMixtureToRepairsDataPageDto: AssignMixtureToRepairsDataPageDto
  ): Observable<RepairAssignedToMixtureListDto> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...assignMixtureToRepairsDataPageDto },
    });

    return this.httpClient
      .get<ApiResponseDto<RepairAssignedToMixtureListDto>>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/assign`), { params })
      .pipe(map((response: ApiResponseDto<RepairAssignedToMixtureListDto>) => response.data));
  }

  public assignMixtureToRepairs(mixtureId: string, mixtureRepairsDto: AssignMixtureToRepairsDto): Observable<void> {
    return this.httpClient.post<void>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/assign`), mixtureRepairsDto);
  }

  public editAssignedMixtureToRepairs(mixtureId: string, mixtureRepairsDto: AssignMixtureToRepairsDto): Observable<void> {
    return this.httpClient.put<void>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/assign`), mixtureRepairsDto);
  }

  public getMixtureLayersWithIngredientsAndConfiguration(
    mixtureId: string,
    prepareMixtureConfigurationsFilters: PrepareMixtureConfigurationsFiltersDto
  ): Observable<MixtureLayersWithIngredientsAndConfigurationsDto> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...prepareMixtureConfigurationsFilters },
    });

    return this.httpClient
      .get<ApiResponseDto<MixtureLayersWithIngredientsAndConfigurationsDto>>(
        ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/ingredients`),
        { params }
      )
      .pipe(map((response: ApiResponseDto<MixtureLayersWithIngredientsAndConfigurationsDto>) => response.data));
  }

  public getMixtureSourceDetails(mixtureId: string): Observable<MixtureSourceDetailsDto> {
    return this.httpClient
      .get<ApiResponseDto<MixtureSourceDetailsDto>>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/source-details`))
      .pipe(map((response: ApiResponseDto<MixtureSourceDetailsDto>) => response.data));
  }

  public updateMixtureFormulaId(mixtureId: string, mixtureFormulaIdFormDto: MixtureFormulaIdFormDto): Observable<void> {
    return this.httpClient.patch<void>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/source-change`), mixtureFormulaIdFormDto);
  }

  public updateMixtureAmountAndMethod(mixtureId: string, mixtureAmountAndMethodFormDto: MixtureAmountAndMethodFormDto): Observable<void> {
    return this.httpClient.put<void>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/weighing`), mixtureAmountAndMethodFormDto);
  }

  public getMixturePrices(id: string, mixturePriceFiltersDto: MixturePricesFiltersDto): Observable<MixturePricesDto> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...mixturePriceFiltersDto },
    });

    return this.httpClient
      .get<ApiResponseDto<MixturePricesDto>>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${id}/prices`), { params })
      .pipe(map((response: ApiResponseDto<MixturePricesDto>) => response.data));
  }

  public getMixtureHistoryLayerDetails(mixtureId: string): Observable<MixtureRecipeHistoryLayerDetailsDto> {
    return this.httpClient
      .get<ApiResponseDto<MixtureRecipeHistoryLayerDetailsDto>>(ApiUrlUtil.getApiUrl(`mixture/mixtures/${mixtureId}/layers`))
      .pipe(map((response: ApiResponseDto<MixtureRecipeHistoryLayerDetailsDto>) => response.data));
  }

  public getMixtureMeasurementList(
    mixtureMeasurementListDataPage: MixtureMeasurementListDataPageDto
  ): Observable<ListDto<MixtureMeasurementListItemDto>> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject: { ...mixtureMeasurementListDataPage },
    });

    return this.httpClient
      .get<ApiResponseDto<ListDto<MixtureMeasurementListItemDto>>>(ApiUrlUtil.getApiUrl('mixture/dictionaries/measurements'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<MixtureMeasurementListItemDto>>) => response.data));
  }
}
