import { AssignMixtureToRepairsDataPageDto } from '../../dtos/assign-mixture-to-repairs-data-page/assign-mixture-to-repairs-data-page.dto';
import { AssignMixtureToRepairsDataPageModel } from '../../models/assign-mixture-to-repairs-data-page/assign-mixture-to-repairs-data-page.model';

export class AssignMixtureToRepairsDataPageDtoCreator {
  public static create(model: AssignMixtureToRepairsDataPageModel): AssignMixtureToRepairsDataPageDto {
    return {
      layer_number: model.layerNumber,
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      page: model.page,
    };
  }
}
