import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { ModalModule } from '../modal/modal.module';
import { FormsModule } from '../forms/forms.module';
import { LinkedColorsModule } from '../linked-colors/linked-colors.module';
import { ColorCombinationsModule } from '../color-combinations/color-combinations.module';
import { CurrentSettingsService } from '../settings/services/current-settings/current-settings.service';
import { RecipeDetailsInfoComponent } from './components/recipe-details-info/recipe-details-info.component';
import { RecipeDetailsIngredientsComponent } from './components/recipe-details-ingredients/recipe-details-ingredients.component';
import { RecipeDetailsSpectralChartComponent } from './components/recipe-details-spectral-chart/recipe-details-spectral-chart.component';
import { RecipeDetailsMeasuringAnglesFormComponent } from './components/recipe-details-measuring-angles-form/recipe-details-measuring-angles-form.component';
import { RecipeDetailsSpectralChartLegendComponent } from './components/recipe-details-spectral-chart-legend/recipe-details-spectral-chart-legend.component';
import { RecipeDetailsAnglesComponent } from './components/recipe-details-angles/recipe-details-angles.component';
import { RecipeDetailsAngleColorSpectrumPreviewComponent } from './components/recipe-details-angle-color-spectrum-preview/recipe-details-angle-color-spectrum-preview.component';
import { RecipeDetailsPricesComponent } from './components/recipe-details-prices/recipe-details-prices.component';
import { ChartsModule } from '../charts/charts.module';
import { RecipeIngredientNameComponent } from '../recipes/components/recipe-ingredient-name/recipe-ingredient-name.component';
import { RecipeDetailsService } from './services/recipe-details/recipe-details.service';
import { RecipeDetailsModalComponent } from './components/recipe-details-modal/recipe-details-modal.component';
import { RecipeDetailsLinkedColorsModalComponent } from './components/recipe-details-linked-colors-modal/recipe-details-linked-colors-modal.component';
import { RecipeDetailsColorCombinationsModalComponent } from './components/recipe-details-color-combinations-modal/recipe-details-color-combinations-modal.component';
import { RecipeDetailsTabsService } from './services/recipe-details-tabs/recipe-details-tabs.service';
import { RecipesService } from '../recipes/services/recipes/recipes.service';
import { ModalService } from '../modal/services/modal.service';
import { UserRolesModule } from '../user-roles/user-roles.module';
import { RecipeDetailsColorSystemSelectorComponent } from './components/recipe-details-color-system-selector/recipe-details-color-system-selector.component';
import { RecipeDetailsTabsComponent } from './components/recipe-details-tabs/recipe-details-tabs.component';
import { RecipeDetailsMixButtonComponent } from './components/recipe-details-mix-button/recipe-details-mix-button.component';
import { RecipeDetailsAddColorIssueButtonComponent } from './components/recipe-details-add-color-issue-button/recipe-details-add-color-issue-button.component';
import { RecipeDetailsBaseModalComponent } from './components/recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeDetailsModalService } from './services/recipe-details-modal/recipe-details-modal.service';
import { RecipeDetailsCorrectionProcessModalComponent } from './components/recipe-details-correction-process-modal/recipe-details-correction-process-modal.component';
import { CorrectionProcessRecipeTypeToggleBoxComponent } from './components/correction-process-recipe-type-toggle-box/correction-process-recipe-type-toggle-box.component';

@NgModule({
  declarations: [
    RecipeDetailsInfoComponent,
    RecipeDetailsIngredientsComponent,
    RecipeDetailsSpectralChartComponent,
    RecipeDetailsMeasuringAnglesFormComponent,
    RecipeDetailsSpectralChartLegendComponent,
    RecipeDetailsAnglesComponent,
    RecipeDetailsAngleColorSpectrumPreviewComponent,
    RecipeDetailsPricesComponent,
    RecipeDetailsModalComponent,
    RecipeDetailsLinkedColorsModalComponent,
    RecipeDetailsColorCombinationsModalComponent,
    RecipeDetailsColorSystemSelectorComponent,
    RecipeDetailsTabsComponent,
    RecipeDetailsMixButtonComponent,
    RecipeDetailsAddColorIssueButtonComponent,
    RecipeDetailsBaseModalComponent,
    RecipeDetailsCorrectionProcessModalComponent,
    CorrectionProcessRecipeTypeToggleBoxComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    ModalModule,
    FormsModule,
    LinkedColorsModule,
    ColorCombinationsModule,
    ChartsModule,
    RecipeIngredientNameComponent,
    UserRolesModule,
    NgScrollbarModule,
  ],
  providers: [
    RecipesService,
    RecipeDetailsService,
    RecipeDetailsTabsService,
    CurrentSettingsService,
    RecipeDetailsModalService,
    ModalService,
  ],
  exports: [
    RecipeDetailsInfoComponent,
    RecipeDetailsPricesComponent,
    RecipeDetailsIngredientsComponent,
    RecipeDetailsSpectralChartComponent,
    RecipeDetailsAnglesComponent,
    RecipeDetailsMixButtonComponent,
    RecipeDetailsAddColorIssueButtonComponent,
  ],
})
export class RecipeDetailsModule {}
