import { DateUtil } from '../../../shared/utils/date/date.util';
import { MixtureListDataPageDto } from '../../dtos/mixture-list-data-page/mixture-list-data-page.dto';
import { MixtureListDataPageModel } from '../../models/mixture-list-data-page/mixture-list-data-page.model';

export class MixtureListDataPageDtoCreator {
  public static create(model: MixtureListDataPageModel): MixtureListDataPageDto {
    return {
      page: model.page,
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      ...(model.brand && { brand: model.brand }),
      ...(model.colorCode && { color_code: model.colorCode }),
      ...(model.colorName && { color_name: model.colorName }),
      ...(model.createdAtFrom && { created_at_from: DateUtil.convertToApiFormat(model.createdAtFrom) }),
      ...(model.createdAtTo && { created_at_to: DateUtil.convertToApiFormat(model.createdAtTo, true) }),
      ...(model.measurementId && { measurement_id: model.measurementId }),
    };
  }
}
