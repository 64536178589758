import { AddMixtureFormModel } from '../../models/add-mixture-form/add-mixture-form.model';
import { AddMixtureFormDto } from '../../dtos/add-mixture-form/add-mixture-form.dto';
import { AddMixtureLayerFormDto } from '../../dtos/add-mixture-layer-form/add-mixture-layer-form.dto';
import { AddMixtureLayerFormModel } from '../../models/add-mixture-layer-form/add-mixture-layer-form.model';
import { AddMixtureLayerFormDtoCreator } from '../add-mixture-layer-form-dto/add-mixture-layer-form-dto.creator';

export class AddMixtureFormDtoCreator {
  public static create(model: AddMixtureFormModel): AddMixtureFormDto {
    return {
      id: model.id,
      source: {
        id: model.source.id,
        type: model.source.type,
        ...(model.source.originalType && { original_type: model.source.originalType }),
        ...(model.source.searchFiltersSignature && { search_filters_signature: model.source.searchFiltersSignature }),
        ...(model.source.measurementId && { measurement_id: model.source.measurementId }),
      },
      layers_number: model.layersNumber,
      layers: AddMixtureFormDtoCreator.createLayersList(model.layers),
      ...(model.repairId && { repair_id: model.repairId }),
    };
  }

  private static createLayersList(layersList: Array<AddMixtureLayerFormModel>): Array<AddMixtureLayerFormDto> {
    return layersList.map((layer: AddMixtureLayerFormModel) => AddMixtureLayerFormDtoCreator.create(layer));
  }
}
