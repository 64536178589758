import { RecipeListItemDto } from '../../dtos/recipe-list-item/recipe-list-item.dto';
import { RecipeListItemModel } from '../../models/recipe-list-item/recipe-list-item.model';
import { RecipeFormulaBasicDto } from '../../dtos/recipe-formula-basic/recipe-formula-basic.dto';
import { RecipeListItemOverallFitabilityCreator } from '../recipe-list-item-overall-fitability/recipe-list-item-overall-fitability.creator';

export class RecipeListItemCreator {
  public static create(dto: RecipeListItemDto): RecipeListItemModel {
    return {
      id: dto.id,
      brand: dto.brand,
      colorId: dto.color_id,
      linkedColor: {
        brandId: dto.linked_color.brand_id,
        colorId: dto.linked_color.color_id,
        isLinkedColor: dto.linked_color.is_linked_color,
      },
      combination: {
        brandId: dto.combination.brand_id,
        colorId: dto.combination.color_id,
        isCombination: dto.combination.is_combination,
      },
      producerColorCode: dto.producer_color_code,
      effect: dto.effect,
      effectThickness: dto.effect_thickness,
      producerColor: dto.producer_color,
      thumbnailColor: dto.thumbnail_color,
      colorBox: dto.color_box,
      colorBox2k: dto.color_box_2k,
      alternativeDescription: dto.alternative_description,
      favorite: dto.favorite,
      formulas: dto.formulas.map((formulaDto: RecipeFormulaBasicDto) => ({
        id: formulaDto.formula_id,
        colorSystemId: formulaDto.color_system_id,
      })),
      score: dto.score,
      shape: dto.shape,
      overallFitability: dto.overall_fitability && RecipeListItemOverallFitabilityCreator.create(dto.overall_fitability),
      xriteCorrection: null,
      isRecipeWeightedWithCurrentMeasurement: dto.is_weighted_recipe,
    };
  }
}
