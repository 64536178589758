import { RecipeCorrectionFormDto } from '../../dtos/recipe-correction-form/recipe-correction-form.dto';
import { RecipeCorrectionFormModel } from '../../models/recipe-correction-form/recipe-correction-form.model';

export class RecipeCorrectionFormDtoCreator {
  public static create(model: RecipeCorrectionFormModel): RecipeCorrectionFormDto {
    return {
      base_measurement_id: model.baseMeasurementId,
      client_id: model.clientId,
      color_id: model.colorId,
      color_system_id: model.colorSystemId,
      correction_process_id: model.correctionProcessId,
      formula_id: model.formulaId,
      measurement_id: model.measurementId,
      source_id: model.sourceId,
      type: model.type,
    };
  }
}
