import { MixtureDetailsDto } from '../../dtos/mixture-details/mixture-details.dto';
import { MixtureDetailsModel } from '../../models/mixture-details/mixture-details.model';
import { RepairMixtureListItemDto } from '../../dtos/repair-mixture-list-item/repair-mixture-list-item.dto';
import { RepairMixtureListItemModel } from '../../models/repair-mixture-list-item/repair-mixture-list-item.model';

export class MixtureDetailsCreator {
  public static create(dto: MixtureDetailsDto): MixtureDetailsModel {
    return {
      mixtureRepairs: MixtureDetailsCreator.createMixtureRepairs(dto.mixture_repairs),
      layers: dto.layers,
      additionalData: {
        alternative: dto.additional_data.alternative,
        brand: dto.additional_data.brand,
        colorboxId: dto.additional_data.colorbox_id,
        colorCode: dto.additional_data.color_code,
        colorName: dto.additional_data.color_name,
        colorSystemId: dto.additional_data.color_system_id,
        flashcard: dto.additional_data.flashcard,
      },
      actions: {
        isAssignMixture: dto.actions.assign_mixture,
        isColorIssues: dto.actions.color_issues,
        isEditAssignMixture: dto.actions.edit_assign_mixture,
        isHistory: dto.actions.history,
        isShowMeasurement: dto.actions.show_measurement,
        isWeighAgain: dto.actions.weigh_again,
      },
    };
  }

  private static createMixtureRepairs(mixtureRepairs: Array<RepairMixtureListItemDto>): Array<RepairMixtureListItemModel> {
    return mixtureRepairs.map((dto: RepairMixtureListItemDto) => {
      return {
        amount: dto.amount,
        brand: dto.brand,
        testSpraying: dto.test_spraying,
        model: dto.model,
        producerColor: dto.producer_color,
        registrationNumber: dto.registration_number,
        repairNumber: dto.repair_number,
      };
    });
  }
}
