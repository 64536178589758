import { RepairAssignedToMixtureDto } from '../../dtos/repair-assigned-to-mixture/repair-assigned-to-mixture.dto';
import { RepairAssignedToMixtureModel } from '../../models/repair-assigned-to-mixture/repair-assigned-to-mixture.model';

export class RepairAssignedToMixtureCreator {
  public static create(dto: RepairAssignedToMixtureDto): RepairAssignedToMixtureModel | null {
    if (dto.brand === null || dto.id === null || dto.number === null) {
      return null;
    }

    return {
      brand: dto.brand,
      id: dto.id,
      number: dto.number,
    };
  }
}
