import { PrepareMixtureConfigurationsFiltersDto } from '../../dtos/prepare-mixture-configurations-filters/prepare-mixture-configurations-filters.dto';
import { PrepareMixtureConfigurationsFiltersModel } from '../../models/prepare-mixture-configurations-filters/prepare-mixture-configurations-filters.model';
import { IngredientToReplaceDtoCreator } from '../ingredient-to-replace-dto/ingredient-to-replace-dto.creator';

export class PrepareMixtureConfigurationsFiltersDtoCreator {
  public static create(model: PrepareMixtureConfigurationsFiltersModel): PrepareMixtureConfigurationsFiltersDto {
    return {
      amount: +model.amount,
      unit: model.unit,
      ...(model.isRfu !== undefined && { rfu: Number(model.isRfu) }),
      ...(model.isThinner !== undefined && { thinner: Number(model.isThinner) }),
      ...(model.isHardener !== undefined && { hardener: Number(model.isHardener) }),
      ...(model.isAmountWithAddons !== undefined && { amount_with_addons: Number(model.isAmountWithAddons) }),
      ...(model.ingredientsToReplace?.length && {
        ingredients_to_replace: IngredientToReplaceDtoCreator.getSerializedIngredientsToReplace(model.ingredientsToReplace),
      }),
    };
  }
}
