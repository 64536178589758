import { MixtureSourceDetailsAlertDto } from '../../dtos/mixture-source-details-alert/mixture-source-details-alert.dto';
import { MixtureSourceDetailsAlertModel } from '../../models/mixture-source-details-alert/mixture-source-details-alert.model';

export class MixtureSourceDetailsAlertCreator {
  public static create(dto: MixtureSourceDetailsAlertDto): MixtureSourceDetailsAlertModel {
    return {
      message: dto.message,
      type: dto.type,
    };
  }
}
