import { IngredientToReplaceModel } from '../../models/ingredient-to-replace/ingredient-to-replace.model';

export class IngredientToReplaceDtoCreator {
  public static getSerializedIngredientsToReplace(ingredients: Array<IngredientToReplaceModel>): Array<string> {
    return ingredients
      .filter((ingredient: IngredientToReplaceModel) => ingredient.alternativeIngredientId !== null)
      .map((ingredient: IngredientToReplaceModel) => IngredientToReplaceDtoCreator.create(ingredient));
  }

  private static create(model: IngredientToReplaceModel): string {
    return `${model.originalIngredientId}:${model.alternativeIngredientId}`;
  }
}
