import { EditMixtureLayerIngredientsFormModel } from '../../models/edit-mixture-layer-ingredients-data/edit-mixture-layer-ingredients-data.model';
import { EditMixtureLayerIngredientsFormDto } from '../../dtos/edit-mixture-layer-ingredients-data/edit-mixture-layer-ingredients-data.dto';

export class EditMixtureLayerIngredientsFormDtoCreator {
  public static create(model: EditMixtureLayerIngredientsFormModel): EditMixtureLayerIngredientsFormDto {
    return {
      dosed_amount: model.dosedAmount,
      id: model.id,
      left_amount: model.leftAmount,
      recipe_amount: model.recipeAmount,
    };
  }
}
