import { EditMixtureFormDto } from '../../dtos/edit-mixture-form/edit-mixture-form.dto';
import { EditMixtureFormModel } from '../../models/edit-mixture-form/edit-mixture-form.model';
import { EditMixtureLayerFormDtoCreator } from '../edit-mixture-layer-form-dto/edit-mixture-layer-form-dto.creator';

export class EditMixtureFormDtoCreator {
  public static create(model: EditMixtureFormModel): EditMixtureFormDto {
    return {
      layer: EditMixtureLayerFormDtoCreator.create(model.layer),
    };
  }
}
