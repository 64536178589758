import { MixtureAlternativeIngredientDto } from '../../dtos/mixture-alternative-ingredient/mixture-alternative-ingredient.dto';
import { MixtureAlternativeIngredientModel } from '../../models/mixture-alternative-ingredient/mixture-alternative-ingredient.model';

export class MixtureAlternativeIngredientDtoCreator {
  public static create(model: MixtureAlternativeIngredientModel): MixtureAlternativeIngredientDto {
    return {
      alternative_ingredient_id: model.alternativeIngredientId,
      original_ingredient_id: model.originalIngredientId,
    };
  }
}
