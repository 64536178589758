import { MixtureRecipeHistoryLayerDetailsDto } from '../../dtos/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.dto';
import { MixtureRecipeHistoryLayerDetailsModel } from '../../models/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.model';
import { AddMixtureLayerFormDto } from '../../dtos/add-mixture-layer-form/add-mixture-layer-form.dto';
import { AddMixtureLayerFormCreator } from '../mixture-recipe-history-layer/add-mixture-layer-form.creator';

export class MixtureRecipeHistoryLayerDetailsCreator {
  public static create(dto: MixtureRecipeHistoryLayerDetailsDto): MixtureRecipeHistoryLayerDetailsModel {
    return {
      amount: dto.amount,
      source: {
        id: dto.source.id,
        type: dto.source.type,
        ...(dto.source.original_type && { originalType: dto.source.original_type }),
      },
      isRfu: dto.rfu,
      layers: dto.layers.map((layer: AddMixtureLayerFormDto) => AddMixtureLayerFormCreator.create(layer)),
    };
  }
}
