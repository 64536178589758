import { MixtureFormulaIdFormDto } from '../../dtos/mixture-formula-id-form/mixture-formula-id-form.dto';
import { MixtureFormulaIdFormModel } from '../../models/mixture-formula-id-form/mixture-formula-id-form.model';

export class MixtureFormulaIdFormDtoCreator {
  public static create(model: MixtureFormulaIdFormModel): MixtureFormulaIdFormDto {
    return {
      formula_id: model.formulaId,
    };
  }
}
