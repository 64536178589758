import { AddMixtureLayerFormModel } from '../../models/add-mixture-layer-form/add-mixture-layer-form.model';
import { AddMixtureLayerFormDto } from '../../dtos/add-mixture-layer-form/add-mixture-layer-form.dto';
import { AddMixtureLayerIngredientsFormDto } from '../../dtos/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.dto';
import { AddMixtureLayerIngredientsFormModel } from '../../models/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.model';

export class AddMixtureLayerFormCreator {
  public static create(dto: AddMixtureLayerFormDto): AddMixtureLayerFormModel {
    return {
      layerNumber: dto.layer_number,
      ingredients: this.createIngredients(dto.ingredients),
    };
  }

  private static createIngredients(ingredients: Array<AddMixtureLayerIngredientsFormDto>): Array<AddMixtureLayerIngredientsFormModel> {
    return ingredients.map((ingredient: AddMixtureLayerIngredientsFormDto) => {
      return {
        id: ingredient.id,
        recipeAmount: ingredient.recipe_amount,
        dosedAmount: ingredient.dosed_amount,
        leftAmount: ingredient.left_amount,
        alternatives: ingredient.alternatives,
      };
    });
  }
}
