import { ListPaginationCreator } from '../../../shared/creators/list-pagination/list-pagination.creator';
import { RepairAssignedToMixtureListItemDto } from '../../dtos/repair-assigned-to-mixture-list-item/repair-assigned-to-mixture-list-item.dto';
import { RepairAssignedToMixtureListDto } from '../../dtos/repair-assigned-to-mixture-list/repair-assigned-to-mixture-list.dto';
import { RepairAssignedToMixtureListItemModel } from '../../models/repair-assigned-to-mixture-list-item/repair-assigned-to-mixture-list-item.model';
import { RepairAssignedToMixtureListModel } from '../../models/repair-assigned-to-mixture-list/repair-assigned-to-mixture-list.model';
import { RepairAssignedToMixtureListItemCreator } from '../repair-assigned-to-mixture-list-item/repair-assigned-to-mixture-list-item.creator';

export class RepairAssignedToMixtureListCreator {
  public static create(dto: RepairAssignedToMixtureListDto): RepairAssignedToMixtureListModel {
    return {
      items: RepairAssignedToMixtureListCreator.createItemsList(dto.items),
      pagination: ListPaginationCreator.create(dto),
      amountLeft: dto.additional_data.amount_left,
      amountWeighted: dto.additional_data.amount_weighted,
    };
  }

  private static createItemsList(repairs: Array<RepairAssignedToMixtureListItemDto>): Array<RepairAssignedToMixtureListItemModel> {
    return repairs.map((repair: RepairAssignedToMixtureListItemDto) => RepairAssignedToMixtureListItemCreator.create(repair));
  }
}
