import { MixtureOwnRecipeSourceDetailsDto } from '../../dtos/mixture-own-recipe-source-details/mixture-own-recipe-source-details.dto';
import { MixtureOwnRecipeSourceDetailsModel } from '../../models/mixture-own-recipe-source-details/mixture-own-recipe-source-details.model';

export class MixtureOwnRecipeSourceDetailsCreator {
  public static create(dto: MixtureOwnRecipeSourceDetailsDto): MixtureOwnRecipeSourceDetailsModel {
    return {
      description: dto.description,
      brand: dto.brand,
      alternativeDescription: dto.alternative_description,
      colorSystem: dto.color_system,
      producerColorCode: dto.producer_color_code,
      producerColorName: dto.producer_color_name,
      primer: {
        hexColor: dto.primer.hex_color,
        primer: dto.primer.primer,
      },
      recipeDate: dto.recipe_date,
    };
  }
}
