import { AssignMixtureToRepairsDto } from '../../dtos/assign-mixture-to-repairs/assign-mixture-to-repairs.dto';
import { AssignMixtureToRepairsModel } from '../../models/assign-mixture-to-repairs/assign-mixture-to-repairs.model';
import { MixtureRepairDto } from '../../dtos/mixture-repair/mixture-repair.dto';
import { MixingSplitAssignToFixFormModel } from '../../models/mixing-split-assign-to-fix-form/mixing-split-assign-to-fix-form.model';

export class AssignMixtureToRepairsDtoCreator {
  public static create(model: AssignMixtureToRepairsModel): AssignMixtureToRepairsDto {
    return {
      mixture_repairs: AssignMixtureToRepairsDtoCreator.createMixtureRepairs(model.mixtureRepairs),
      layer_number: model.layerNumber,
    };
  }

  private static createMixtureRepairs(mixtureRepairs: Array<MixingSplitAssignToFixFormModel>): Array<MixtureRepairDto> {
    return mixtureRepairs.map((mixtureRepair: MixingSplitAssignToFixFormModel) => {
      return {
        test_spraying: mixtureRepair.testSpraying,
        amount: Number(mixtureRepair.amount),
        repair_id: mixtureRepair.fix?.repairId,
      };
    });
  }
}
