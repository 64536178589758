import { MixtureLayerWithIngredientsDto } from '../../dtos/mixture-layer-with-ingredients/mixture-layer-with-ingredients.dto';
import { MixtureLayersWithIngredientsAndConfigurationsDto } from '../../dtos/mixture-layers-with-ingredients-and-configurations/mixture-layers-with-ingredients-and-configurations.dto';
import { MixtureLayerWithIngredientsModel } from '../../models/mixture-layer-with-ingredients/mixture-layer-with-ingredients.model';
import { MixtureLayersWithIngredientsAndConfigurationsModel } from '../../models/mixture-layers-with-ingredients-and-configurations/mixture-layers-with-ingredients-and-configurations.model';
import { MixtureLayerWithIngredientsCreator } from '../mixture-layer-with-ingredients/mixture-layer-with-ingredients.creator';

export class MixtureLayersWithIngredientsAndConfigurationsCreator {
  public static create(dto: MixtureLayersWithIngredientsAndConfigurationsDto): MixtureLayersWithIngredientsAndConfigurationsModel {
    return {
      configuration: {
        hardener: {
          checked: dto.configuration.hardener.checked,
          disabled: dto.configuration.hardener.disabled,
          show: dto.configuration.hardener.show,
        },
        rfu: {
          checked: dto.configuration.rfu.checked,
          disabled: dto.configuration.rfu.disabled,
          show: dto.configuration.rfu.show,
        },
        thinner: {
          checked: dto.configuration.thinner.checked,
          disabled: dto.configuration.thinner.disabled,
          show: dto.configuration.thinner.show,
        },
        amountWithAddons: {
          checked: dto.configuration.amount_with_addons.checked,
          disabled: dto.configuration.amount_with_addons.disabled,
          show: dto.configuration.amount_with_addons.show,
        },
      },
      layers: MixtureLayersWithIngredientsAndConfigurationsCreator.createLayersList(dto.layers),
    };
  }

  private static createLayersList(layerList: Array<MixtureLayerWithIngredientsDto>): Array<MixtureLayerWithIngredientsModel> {
    return layerList.map((layer: MixtureLayerWithIngredientsDto) => MixtureLayerWithIngredientsCreator.create(layer));
  }
}
