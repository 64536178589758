import { MixturePricesDto } from '../../dtos/mixture-prices/mixture-prices.dto';
import { MixturePricesModel } from '../../models/mixture-prices/mixture-prices.model';

export class MixturePricesCreator {
  public static create(dto: MixturePricesDto): MixturePricesModel {
    return {
      sumAddonsPrice: dto.sum_addons_price,
      sumSellingPrice: dto.sum_selling_price,
      sumTotalPrice: dto.sum_total_price,
    };
  }
}
