import { Component, Input, OnInit } from '@angular/core';

import { finalize, Observable } from 'rxjs';

import { RecipeDetailsBaseModalComponent } from '../recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeDetailsTabModel } from '../../models/recipe-details-tab/recipe-details-tab.model';
import { CorrectionProcessTimelineEventMixtureModel } from '../../../correction-processes/models/correction-process-timeline-event-mixture/correction-process-timeline-event-mixture.model';
import { CorrectionProcessTimelineEventMixtureRecipeTypeEnum } from '../../../correction-processes/enums/correction-process-timeline-event-mixture-recipe-type/correction-process-timeline-event-mixture-recipe-type.enum';
import { RecipeDetailsTabsEnum } from '../../enums/recipe-details-tabs/recipe-details-tabs.enum';
import { RecipePriceFormModel } from '../../../recipes/models/recipe-price-form/recipe-price-form.model';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';

@Component({
  selector: 'app-recipe-details-modal[activeMixture][mixtureToCompare][measurementId]',
  templateUrl: './recipe-details-correction-process-modal.component.html',
  styleUrls: ['./recipe-details-correction-process-modal.component.scss'],
})
export class RecipeDetailsCorrectionProcessModalComponent extends RecipeDetailsBaseModalComponent implements OnInit {
  @Input() public activeMixture!: CorrectionProcessTimelineEventMixtureModel;
  @Input() public mixtureToCompare!: CorrectionProcessTimelineEventMixtureModel | null;

  public novolOrWeightedMixture!: CorrectionProcessTimelineEventMixtureModel;
  public correctionMixture: CorrectionProcessTimelineEventMixtureModel | null = null;
  public currentSelectedMixture!: CorrectionProcessTimelineEventMixtureModel;

  public ngOnInit(): void {
    this.initMixturesData();
    this.initToggleBox();
    this.initActiveOptionForToggleBox();
    this.initActiveMixtureData();
    this.initTabs();
    this.listenToActiveMixtureChange();
  }

  public override initTabs(): void {
    const recipeDetailsModalParams: RecipeDetailsModalParamsModel = {
      colorId: this.currentSelectedMixture.colorId,
      formulaId: this.currentSelectedMixture.formulaId,
      recipeType: this.currentSelectedMixture.type,
      id: this.currentSelectedMixture.recipeSignature,
      correctionName: this.currentSelectedMixture.typeName,
    };

    this.recipeDetailsTabsService.initTabs(recipeDetailsModalParams, this?.measurementId);

    if (!this.selectedTab) {
      return;
    }

    this.updateIsShownRecipeTypeToggle(this.selectedTab);
    this.updateToggleBoxByActiveTab(this.selectedTab);
    this.loadTabData(this.selectedTab);
  }

  public override loadTabData(tab: RecipeDetailsTabModel | null): void {
    if (!tab) {
      return;
    }

    this.isContentLoading = true;

    this.loadTabDataStrategy(tab);
  }

  public override updateIsShownRecipeTypeToggle(tab: RecipeDetailsTabModel): void {
    const isNotIngredientsTab: boolean = tab.id !== RecipeDetailsTabsEnum.ingredients;
    const isNotPreviewTab: boolean = tab.id !== RecipeDetailsTabsEnum.preview;

    this.isShownRecipeTypeToggle = isNotIngredientsTab && isNotPreviewTab && !!(this.activeMixture && this.mixtureToCompare);
  }

  public override recipePriceFormValueChange(recipePriceForm: RecipePriceFormModel): void {
    this.recipePriceForm = recipePriceForm;
    this.isContentLoading = true;

    this.initRecipeDetailsPrices();
  }

  public override updateFormulaSpectralChart(): void {
    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaSpectralChartData(
        this.currentSelectedMixture.type,
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.spectralChartGeometriesIds
      )
      .subscribe();
  }

  public override updateFormulaAngles(): void {
    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaAnglesData(this.colorId, this.formulaId, this.measurementId, this.anglesGeometriesIds)
      .subscribe();
  }

  protected override loadTabDataStrategy(tab: RecipeDetailsTabModel): void {
    switch (tab.id) {
      case RecipeDetailsTabsEnum.info:
        this.initRecipeDetailsInfo();
        break;
      case RecipeDetailsTabsEnum.prices:
        this.initRecipeDetailsPrices();
        break;
      case RecipeDetailsTabsEnum.ingredients:
        this.initMixtureIngredients();
        break;
      case RecipeDetailsTabsEnum.chart:
        this.initRecipeDetailsSpectralChart();
        break;
      case RecipeDetailsTabsEnum.preview:
        this.initRecipeDetailsPreview();
        break;
      default:
        this.initRecipeDetailsInfo();
        break;
    }
  }

  protected override initRecipeDetailsInfo(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    const mixtureId: string | undefined = this.getMixtureIdForCurrentSelectedMixture();

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaDetails(this.colorId, this.formulaId, this.currentSelectedMixture.type, this.measurementId, mixtureId)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override initRecipeDetailsPrices(): void {
    if (!this.formulaId) {
      return;
    }

    const mixtureId: string | undefined = this.getMixtureIdForCurrentSelectedMixture();

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaPrices(
        this.formulaId,
        this.recipePriceForm,
        this.currentSelectedMixture.type,
        this.measurementId,
        this.currentSelectedMixture.recipeSignature,
        mixtureId
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override refreshRecipeFormulaIngredients(): void {
    this.initMixtureIngredients();
  }

  protected override initRecipeDetailsPreview(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    this.isContentLoading = true;

    const canShowCompareToCorrection: boolean =
      this.activeMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.correction ||
      this.mixtureToCompare?.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.correction;

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaAnglesData(this.colorId, this.formulaId, this.measurementId, this.anglesGeometriesIds, canShowCompareToCorrection)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override initRecipeDetailsSpectralChart(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaSpectralChartData(
        this.recipeTypeForm.value,
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.spectralChartGeometriesIds
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override listenToTabChanges(): void {
    this.subscription = this.recipeDetailsTabsService.activeTab$.subscribe((tab: RecipeDetailsTabModel) => {
      if (!tab) {
        return;
      }

      this.selectedTab = tab;
      this.updateIsShownRecipeTypeToggle(tab);
      this.updateToggleBoxByActiveTab(tab);
      this.loadTabData(tab);
    });
  }

  private initMixturesData(): void {
    this.initNovolOrWeightedMixture();
    this.initCorrectionMixture();
    this.initCurrentSelectedMixture();
  }

  private initNovolOrWeightedMixture(): void {
    if (
      this.activeMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.novolRecipe ||
      this.activeMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe
    ) {
      this.novolOrWeightedMixture = this.activeMixture;
    } else if (
      this.mixtureToCompare &&
      (this.mixtureToCompare.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.novolRecipe ||
        this.mixtureToCompare.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe)
    ) {
      this.novolOrWeightedMixture = this.mixtureToCompare;
    }
  }

  private initCorrectionMixture(): void {
    if (this.activeMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.correction) {
      this.correctionMixture = this.activeMixture;
    } else if (this.mixtureToCompare && this.mixtureToCompare.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.correction) {
      this.correctionMixture = this.mixtureToCompare;
    }
  }

  private initCurrentSelectedMixture(): void {
    this.currentSelectedMixture = this.activeMixture;
  }

  private initToggleBox(): void {
    this.recipeTypeOptions = [
      {
        label: this.novolOrWeightedMixture.typeName,
        value: this.novolOrWeightedMixture.type,
        isDisabled: false,
      },
    ];

    if (this.correctionMixture) {
      this.recipeTypeOptions.push({
        label: this.correctionMixture.typeName,
        value: this.correctionMixture.type,
        isDisabled: false,
      });
    }
  }

  private initActiveMixtureData(): void {
    this.initActiveMixtureColorId();
    this.initActiveMixtureFormulaId();
    this.initSelectedRecipeData();
  }

  private initActiveMixtureColorId(): void {
    this.colorId = this.activeMixture.colorId;
  }

  private initActiveMixtureFormulaId(): void {
    this.formulaId = this.activeMixture.formulaId;
  }

  private initActiveOptionForToggleBox(): void {
    this.recipeTypeForm.setValue(this.currentSelectedMixture.type);
  }

  private initSelectedRecipeData(): void {
    this.colorId = this.activeMixture.colorId;
    this.formulaId = this.activeMixture.formulaId;
  }

  private initMixtureIngredients(): void {
    if (!this.measurementId) {
      return;
    }

    const mixtureId: string | undefined = this.getMixtureIdForCurrentSelectedMixture();

    let $ingredients: Observable<void> = this.recipeDetailsModalService.initRecipeFormulaIngredients(
      this.formulaId,
      this.currentSelectedMixture.type,
      this.measurementId,
      this.currentSelectedMixture.recipeSignature,
      mixtureId
    );

    if (this.correctionMixture) {
      const mixtureId: string | undefined = this.getMixtureIdForNovolOrWeightedMixture();

      $ingredients = this.recipeDetailsModalService.initRecipeFormulaCorrectionIngredients(
        this.measurementId,
        this.currentSelectedMixture.recipeSignature,
        this.novolOrWeightedMixture.type,
        mixtureId
      );
    }

    this.isContentLoading = true;

    this.subscription = $ingredients
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  private getMixtureIdForCurrentSelectedMixture(): string | undefined {
    return this.currentSelectedMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe
      ? this.currentSelectedMixture.sourceId
      : undefined;
  }

  private getMixtureIdForNovolOrWeightedMixture(): string | undefined {
    return this.novolOrWeightedMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe
      ? this.novolOrWeightedMixture.sourceId
      : undefined;
  }

  private setCurrentSelectedMixture(): void {
    if (this.recipeTypeForm.value === this.novolOrWeightedMixture.type) {
      this.currentSelectedMixture = this.novolOrWeightedMixture;
    } else if (this.correctionMixture && this.recipeTypeForm.value === this.correctionMixture.type) {
      this.currentSelectedMixture = this.correctionMixture;
    }
  }

  private updateToggleBoxByActiveTab(tab: RecipeDetailsTabModel): void {
    if (!this.novolOrWeightedMixture) {
      return;
    }

    const isWeightedMixtureAndCorrectionMixture: boolean =
      this.novolOrWeightedMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe && !!this.correctionMixture;
    const isActiveSpectralChartTab: boolean = tab.id === RecipeDetailsTabsEnum.chart;

    if (isWeightedMixtureAndCorrectionMixture && isActiveSpectralChartTab) {
      this.recipeTypeOptions[0].isDisabled = true;
      this.recipeTypeForm.setValue(this.correctionMixture?.type as RecipeTypeModel, { emitEvent: false });

      return;
    }

    this.recipeTypeOptions[0].isDisabled = false;
  }

  private listenToActiveMixtureChange(): void {
    this.subscription = this.recipeTypeForm.valueChanges.subscribe({
      next: () => {
        this.setCurrentSelectedMixture();
        this.loadTabData(this.selectedTab);
      },
    });
  }
}
