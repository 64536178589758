import { ListPaginationCreator } from '../../../shared/creators/list-pagination/list-pagination.creator';
import { RecipeListDto } from '../../dtos/recipe-list/recipe-list.dto';
import { RecipeListModel } from '../../models/recipe-list/recipe-list.model';
import { RecipeListItemModel } from '../../models/recipe-list-item/recipe-list-item.model';
import { RecipeListAdditionalDataForSearchWithoutMeasurementModel } from '../../models/recipe-list-additional-data-for-search-without-measurement/recipe-list-additional-data-for-search-without-measurement.model';
import { RecipeListAdditionalDataForSearchWithMeasurementModel } from '../../models/recipe-list-additional-data-for-search-with-measurement/recipe-list-additional-data-for-search-with-measurement.model';
import { RecipeListAdditionalDataForSearchWithoutMeasurementDto } from '../../dtos/recipe-list-additional-data-for-search-without-measurement/recipe-list-additional-data-for-search-without-measurement.dto';
import { RecipeListAdditionalDataForSearchWithMeasurementDto } from '../../dtos/recipe-list-additional-data-for-search-with-measurement/recipe-list-additional-data-for-search-with-measurement.dto';

export class RecipeListCreator {
  public static create(dto: RecipeListDto, items: Array<RecipeListItemModel>): RecipeListModel {
    return {
      items: items,
      pagination: ListPaginationCreator.create(dto),
      additionalData: RecipeListCreator.mapAdditionalData(dto.additional_data),
    };
  }

  private static mapAdditionalData(
    additionalDataDto: RecipeListAdditionalDataForSearchWithoutMeasurementDto | RecipeListAdditionalDataForSearchWithMeasurementDto
  ): RecipeListAdditionalDataForSearchWithoutMeasurementModel | RecipeListAdditionalDataForSearchWithMeasurementModel {
    if (Array.isArray(additionalDataDto)) {
      return additionalDataDto;
    }

    return {
      searchFiltersSignature: additionalDataDto.search_filters_signature,
    };
  }
}
