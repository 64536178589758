import { MixtureMeasurementListItemDto } from '../../dtos/mixture-measurement-list-item/mixture-measurement-list-item.dto';
import { MixtureMeasurementListItemModel } from '../../models/mixture-measurement-list-item/mixture-measurement-list-item.model';

export class MixtureMeasurementListItemCreator {
  public static create(dto: MixtureMeasurementListItemDto): MixtureMeasurementListItemModel {
    return {
      id: dto.id,
      name: dto.name,
    };
  }
}
