import { MixtureRecipeHistoryDto } from '../../dtos/mixture-recipe-history/mixture-recipe-history.dto';
import { MixtureRecipeHistoryModel } from '../../models/mixture-recipe-history/mixture-recipe-history.model';

export class MixtureRecipeHistoryCreator {
  public static create(dto: MixtureRecipeHistoryDto): MixtureRecipeHistoryModel {
    return {
      formulaId: dto.formula_id,
      label: dto.label,
      selected: dto.selected,
      historicalVersion: dto.historical_version,
    };
  }
}
