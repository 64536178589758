import { EditMixtureLayerFormDto } from '../../dtos/edit-mixture-layer-form/edit-mixture-layer-form.dto';
import { EditMixtureLayerIngredientsFormModel } from '../../models/edit-mixture-layer-ingredients-data/edit-mixture-layer-ingredients-data.model';
import { EditMixtureLayerIngredientsFormDto } from '../../dtos/edit-mixture-layer-ingredients-data/edit-mixture-layer-ingredients-data.dto';
import { EditMixtureLayerIngredientsFormDtoCreator } from '../edit-mixture-layer-ingredients-data-dto/edit-mixture-layer-ingredients-data-dto.creator';
import { EditMixtureLayerFormModel } from '../../models/edit-mixture-layer-form/edit-mixture-layer-form.model';

export class EditMixtureLayerFormDtoCreator {
  public static create(model: EditMixtureLayerFormModel): EditMixtureLayerFormDto {
    return {
      ingredients: EditMixtureLayerFormDtoCreator.createIngredientsList(model.ingredients),
      layer_number: model.layerNumber,
    };
  }

  private static createIngredientsList(
    ingredientsList: Array<EditMixtureLayerIngredientsFormModel>
  ): Array<EditMixtureLayerIngredientsFormDto> {
    return ingredientsList.map((ingredient: EditMixtureLayerIngredientsFormModel) =>
      EditMixtureLayerIngredientsFormDtoCreator.create(ingredient)
    );
  }
}
