import { RecipeFormulaIngredientsDataModel } from '../../models/recipe-formula-ingredients-data/recipe-formula-ingredients-data.model';
import { RecipeFormulaIngredientsDataDto } from '../../../recipes/dtos/recipe-formula-ingredients-data/recipe-formula-ingredients-data.dto';

export class RecipeFormulaIngredientsDataDtoCreator {
  public static create(model: RecipeFormulaIngredientsDataModel): RecipeFormulaIngredientsDataDto {
    return {
      ...(model.amount && { amount: model.amount }),
      ...(model.unit && { unit: model.unit }),
      ...(model.isRfu !== undefined && { rfu: model.isRfu }),
      ...(model.isThinner !== undefined && { thinner: model.isThinner }),
      ...(model.isHardener !== undefined && { hardener: model.isHardener }),
      ...(model.measurementId && { measurement_id: model.measurementId }),
      ...(model.recipeSignature && { recipe_signature: model.recipeSignature }),
      ...(model.mixtureId && { mixture_id: model.mixtureId }),
      type: model.type,
    };
  }
}
