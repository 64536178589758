import { MixtureAddonSourceDetailsDto } from '../../dtos/mixture-addon-source-details/mixture-addon-source-details.dto';
import { MixtureAddonSourceDetailsModel } from '../../models/mixture-addon-source-details/mixture-addon-source-details.model';

export class MixtureAddonSourceDetailsCreator {
  public static create(dto: MixtureAddonSourceDetailsDto): MixtureAddonSourceDetailsModel {
    return {
      name: dto.name,
    };
  }
}
