import { MixtureIngredientDto } from '../../dtos/mixture-ingredient/mixture-ingredient.dto';
import { MixtureLayerWithIngredientsDto } from '../../dtos/mixture-layer-with-ingredients/mixture-layer-with-ingredients.dto';
import { MixtureIngredientModel } from '../../models/mixture-ingredient/mixture-ingredient.model';
import { MixtureLayerWithIngredientsModel } from '../../models/mixture-layer-with-ingredients/mixture-layer-with-ingredients.model';
import { MixtureIngredientCreator } from '../mixture-ingredient/mixture-ingredient.creator';

export class MixtureLayerWithIngredientsCreator {
  public static create(dto: MixtureLayerWithIngredientsDto): MixtureLayerWithIngredientsModel {
    return {
      ingredients: MixtureLayerWithIngredientsCreator.createIngredientList(dto.ingredients),
      layerNumber: dto.layer_number,
    };
  }

  private static createIngredientList(ingredientList: Array<MixtureIngredientDto>): Array<MixtureIngredientModel> {
    return ingredientList.map((ingredient: MixtureIngredientDto) => MixtureIngredientCreator.create(ingredient));
  }
}
