import { MixtureAlternativeIngredientDto } from '../../dtos/mixture-alternative-ingredient/mixture-alternative-ingredient.dto';
import { MixtureAmountAndMethodFormDto } from '../../dtos/mixture-amount-and-method-form/mixture-amount-and-method-form.dto';
import { MixtureAlternativeIngredientModel } from '../../models/mixture-alternative-ingredient/mixture-alternative-ingredient.model';
import { MixtureAmountAndMethodFormModel } from '../../models/mixture-amount-and-method-form/mixture-amount-and-method-form.model';
import { MixtureAlternativeIngredientDtoCreator } from '../mixture-alternative-ingredient-dto/mixture-alternative-ingredient-dto.creator';

export class MixtureAmountAndMethodFormDtoCreator {
  public static create(model: MixtureAmountAndMethodFormModel): MixtureAmountAndMethodFormDto {
    return {
      amount: model.amount,
      method: model.method,
      rfu: model.isRfu,
      alternative_ingredients: MixtureAmountAndMethodFormDtoCreator.createAlternativeIngredientDto(model.alternativeIngredients),
    };
  }

  private static createAlternativeIngredientDto(
    arrayModel: Array<MixtureAlternativeIngredientModel>
  ): Array<MixtureAlternativeIngredientDto> {
    return arrayModel.map((model: MixtureAlternativeIngredientModel) => MixtureAlternativeIngredientDtoCreator.create(model));
  }
}
