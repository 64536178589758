import { AddMixtureLayerIngredientsFormDto } from '../../dtos/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.dto';
import { AddMixtureLayerFormModel } from '../../models/add-mixture-layer-form/add-mixture-layer-form.model';
import { AddMixtureLayerIngredientsFormDtoCreator } from '../add-mixture-layer-ingredients-data-dto/add-mixture-layer-ingredients-data-dto.creator';
import { AddMixtureLayerIngredientsFormModel } from '../../models/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.model';
import { AddMixtureLayerFormDto } from '../../dtos/add-mixture-layer-form/add-mixture-layer-form.dto';

export class AddMixtureLayerFormDtoCreator {
  public static create(model: AddMixtureLayerFormModel): AddMixtureLayerFormDto {
    return {
      ingredients: AddMixtureLayerFormDtoCreator.createIngredientsList(model.ingredients),
      layer_number: model.layerNumber,
    };
  }

  private static createIngredientsList(
    ingredientsList: Array<AddMixtureLayerIngredientsFormModel>
  ): Array<AddMixtureLayerIngredientsFormDto> {
    return ingredientsList.map((ingredient: AddMixtureLayerIngredientsFormModel) =>
      AddMixtureLayerIngredientsFormDtoCreator.create(ingredient)
    );
  }
}
