import { RecipePricesDataModel } from '../../models/recipe-prices-data/recipe-prices-data.model';
import { RecipePricesDataDto } from '../../dtos/recipe-prices-data/recipe-prices-data.dto';
import { NumberUtil } from '../../../shared/utils/number/number.util';

export class RecipePricesDataDtoCreator {
  public static create(model: RecipePricesDataModel): RecipePricesDataDto {
    return {
      amount: NumberUtil.convertToNumberIfHasValue(model.amount) || 0,
      unit: model.unit,
      type: model.type,
      ...(model.measurementId && { measurement_id: model.measurementId }),
      ...(model.mixtureId && { mixture_id: model.mixtureId }),
      ...(model.recipeSignature && { recipe_signature: model.recipeSignature }),
    };
  }
}
