import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeFormulaIngredientsModel } from '../../models/recipe-formula-ingredietns/recipe-formula-ingredients.model';
import { RecipeFormulaIngredientsDataModel } from '../../models/recipe-formula-ingredients-data/recipe-formula-ingredients-data.model';
import { RecipeDetailsModel } from '../../models/recipe-details/recipe-details.model';
import { RecipeDetailsSpectralChartDataModel } from '../../models/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.model';
import { RecipeDetailsSpectralChartFiltersModel } from '../../models/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';
import { RecipeDetailsAnglesFiltersModel } from '../../models/recipe-details-angles-filters/recipe-details-angles-filters.model';
import { RecipeDetailsAnglesDataModel } from '../../models/recipe-details-angles-data/recipe-details-angles-data.model';
import { RecipePricesDataModel } from '../../models/recipe-prices-data/recipe-prices-data.model';
import { RecipePriceModel } from '../../models/recipe-price/recipe-price.model';
import { RecipeLayerForMixingModel } from '../../../recipes/models/recipe-layer-for-mixing/recipe-layer-for-mixing-model.model';
import { RecipeIngredientsForMixingDataModel } from '../../../recipes/models/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { RecipeDetailsHttpService } from '../recipe-details-http/recipe-details-http.service';
import { RecipeFormulaIngredientsDto } from '../../../recipes/dtos/recipe-formula-ingredients/recipe-formula-ingredients.dto';
import { RecipeFormulaIngredientsCreator } from '../../creators/recipe-formula-ingredients/recipe-formula-ingredients.creator';
import { RecipeIngredientsForMixingDataDto } from '../../dtos/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data.dto';
import { RecipeIngredientsForMixingDataDtoCreator } from '../../creators/recipe-ingredients-for-mixing-data/recipe-ingredients-for-mixing-data-dto.creator';
import { RecipeLayerForMixingDto } from '../../dtos/recipe-layer-for-mixing/recipe-layer-for-mixing-model.dto';
import { RecipeLayerForMixingCreator } from '../../creators/recipe-layer-for-mixing/recipe-layer-for-mixing.creator';
import { RecipePriceFormModel } from '../../../recipes/models/recipe-price-form/recipe-price-form.model';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';
import { RecipeDetailsDto } from '../../dtos/recipe-details/recipe-details.dto';
import { RecipeDetailsDtoCreator } from '../../creators/recipe-details-dto/recipe-details-dto.creator';
import { RecipeFormulaDetailsCreator } from '../../creators/recipe-formula-details/recipe-formula-details.creator';
import { RecipeFormulaIngredientsDataDto } from '../../../recipes/dtos/recipe-formula-ingredients-data/recipe-formula-ingredients-data.dto';
import { RecipeFormulaIngredientsDataDtoCreator } from '../../creators/recipe-formula-ingredients-data-dto/recipe-formula-ingredients-data-dto.creator';
import { RecipeDetailsSpectralChartDataCreator } from '../../creators/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.creator';
import { RecipeDetailsSpectralChartFiltersDto } from '../../dtos/recipe-details-spectral-chart-filters/recipe-details-spectral-chart-filters.dto';
import { RecipeDetailsSpectralChartFiltersDtoCreator } from '../../creators/recipe-details-spectral-chart-filters-dto/recipe-details-spectral-chart-filters-dto.creator';
import { RecipeDetailsAnglesFiltersDto } from '../../dtos/recipe-details-angles-filters/recipe-details-angles-filters.dto';
import { RecipeDetailsAnglesFiltersDtoCreator } from '../../creators/recipe-details-angles-filters-dto/recipe-details-angles-filters-dto.creator';
import { RecipeDetailsAnglesDataCreator } from '../../creators/recipe-details-angles-data/recipe-details-angles-data.creator';
import { RecipePricesDataDto } from '../../dtos/recipe-prices-data/recipe-prices-data.dto';
import { RecipePricesDataDtoCreator } from '../../creators/recipe-prices-data-dto/recipe-prices-data-dto.creator';
import { RecipePriceDto } from '../../dtos/recipe-price/recipe-price.dto';
import { RecipePriceCreator } from '../../creators/recipe-price/recipe-price.creator';
import { RecipeDetailsSpectralChartDataDto } from '../../dtos/recipe-details-spectral-chart-data/recipe-details-spectral-chart-data.dto';
import { RecipeDetailsAnglesDataDto } from '../../dtos/recipe-details-angles-data/recipe-details-angles-data.dto';
import { RecipeFormulaDetailsDto } from '../../../recipes/dtos/recipe-formula-details/recipe-formula-details.dto';

@Injectable({
  providedIn: 'root',
})
export class RecipeDetailsService {
  constructor(private recipeDetailsHttpService: RecipeDetailsHttpService) {}

  public getRecipeFormulaDetails(
    colorId: number,
    formulaId: number,
    recipeType: RecipeTypeModel,
    measurementId?: string,
    mixtureId?: string
  ): Observable<RecipeFormulaDetailsModel> {
    if (!recipeType) {
      return EMPTY;
    }

    const recipeDetails: RecipeDetailsModel = {
      type: recipeType,
      measurementId,
      mixtureId,
    };

    const recipeDetailsDto: RecipeDetailsDto = RecipeDetailsDtoCreator.create(recipeDetails);

    return this.recipeDetailsHttpService
      .getRecipeFormulaDetails(colorId, formulaId, recipeDetailsDto)
      .pipe(map((detailsDto: RecipeFormulaDetailsDto) => RecipeFormulaDetailsCreator.create(detailsDto)));
  }

  public getRecipeIngredients(
    formulaId: number,
    type: RecipeTypeModel,
    measurementId?: string,
    recipeSignature?: string,
    mixtureId?: string
  ): Observable<Array<RecipeFormulaIngredientsModel>> {
    if (!formulaId) {
      return EMPTY;
    }

    const recipeFormulaIngredients: RecipeFormulaIngredientsDataModel = {
      type,
      measurementId,
      recipeSignature,
      mixtureId,
    };

    const recipeFormulaIngredientsDataDto: RecipeFormulaIngredientsDataDto =
      RecipeFormulaIngredientsDataDtoCreator.create(recipeFormulaIngredients);

    return this.recipeDetailsHttpService
      .getRecipeIngredients(formulaId, recipeFormulaIngredientsDataDto)
      .pipe(map((listDto: Array<RecipeFormulaIngredientsDto>) => listDto.map(RecipeFormulaIngredientsCreator.create.bind(this))));
  }

  public getRecipeCorrectionIngredients(
    measurementId: string,
    recipeSignature: string,
    type?: RecipeTypeModel,
    mixtureId?: string
  ): Observable<Array<RecipeFormulaIngredientsModel>> {
    return this.recipeDetailsHttpService
      .getRecipeCorrectionIngredients(measurementId, recipeSignature, type, mixtureId)
      .pipe(
        map((listDto: Array<RecipeFormulaIngredientsDto>) =>
          listDto.map((dto: RecipeFormulaIngredientsDto) => RecipeFormulaIngredientsCreator.create(dto))
        )
      );
  }

  public getRecipeIngredientsForMixing(
    formulaId: number,
    recipeIngredientsForMixingDataModel: RecipeIngredientsForMixingDataModel
  ): Observable<Array<RecipeLayerForMixingModel>> {
    const recipeIngredientsForMixingDataDto: RecipeIngredientsForMixingDataDto =
      RecipeIngredientsForMixingDataDtoCreator.create(recipeIngredientsForMixingDataModel);

    return this.recipeDetailsHttpService
      .getRecipeIngredientsForMixing(formulaId, recipeIngredientsForMixingDataDto)
      .pipe(
        map((listDto: Array<RecipeLayerForMixingDto>) =>
          listDto.map((dto: RecipeLayerForMixingDto) => RecipeLayerForMixingCreator.create(dto))
        )
      );
  }

  public getRecipeSpectralChartData(
    recipeType: RecipeTypeModel,
    colorId: number,
    formulaId: number,
    measurementId?: string | null,
    geometries?: Array<string>
  ): Observable<RecipeDetailsSpectralChartDataModel> {
    if (!colorId || !formulaId) {
      return EMPTY;
    }

    const filters: RecipeDetailsSpectralChartFiltersModel = {
      type: recipeType || RecipeTypeEnum.novolRecipe,
      measurementId: measurementId || '',
      geometries: geometries?.length ? geometries : [],
    };

    const chartFiltersDto: RecipeDetailsSpectralChartFiltersDto = RecipeDetailsSpectralChartFiltersDtoCreator.create(filters);

    return this.recipeDetailsHttpService
      .getSpectralChartData(colorId.toString(), formulaId.toString(), chartFiltersDto)
      .pipe(map((dto: RecipeDetailsSpectralChartDataDto) => RecipeDetailsSpectralChartDataCreator.create(dto)));
  }

  public getRecipeAngles(
    colorId: number,
    formulaId: number,
    measurementId?: string | null,
    geometries?: Array<string>,
    includesCorrection?: boolean
  ): Observable<RecipeDetailsAnglesDataModel> {
    if (!colorId || !formulaId) {
      return EMPTY;
    }

    const filters: RecipeDetailsAnglesFiltersModel = {
      measurementId: measurementId || '',
      geometries: geometries?.length ? geometries : [],
      includesCorrection: !!includesCorrection,
    };

    const anglesFiltersDto: RecipeDetailsAnglesFiltersDto = RecipeDetailsAnglesFiltersDtoCreator.create(filters);

    return this.recipeDetailsHttpService
      .getAnglesData(colorId.toString(), formulaId.toString(), anglesFiltersDto)
      .pipe(map((dto: RecipeDetailsAnglesDataDto) => RecipeDetailsAnglesDataCreator.create(dto)));
  }

  public getRecipePrices(
    formulaId: number,
    recipePriceData: RecipePriceFormModel,
    recipeType: RecipeTypeModel,
    measurementId?: string,
    recipeSignature?: string,
    mixtureId?: string
  ): Observable<Array<RecipePriceModel>> {
    if (!formulaId || !recipePriceData) {
      return EMPTY;
    }

    const recipePricesData: RecipePricesDataModel = {
      amount: recipePriceData.amount,
      unit: recipePriceData.unit,
      type: recipeType,
      measurementId,
      recipeSignature,
      mixtureId,
    };

    const recipePricesDataDto: RecipePricesDataDto = RecipePricesDataDtoCreator.create(recipePricesData);

    return this.recipeDetailsHttpService
      .getRecipePrices(formulaId, recipePricesDataDto)
      .pipe(map((listDto: Array<RecipePriceDto>) => listDto.map((dto: RecipePriceDto) => RecipePriceCreator.create(dto))));
  }
}
