import { MixtureIngredientDto } from '../../dtos/mixture-ingredient/mixture-ingredient.dto';
import { MixtureIngredientModel } from '../../models/mixture-ingredient/mixture-ingredient.model';

export class MixtureIngredientCreator {
  public static create(dto: MixtureIngredientDto): MixtureIngredientModel {
    return {
      alternatives: dto.alternatives,
      amount: {
        ascending: dto.amount.amount_ascending,
        value: dto.amount.amount,
      },
      id: dto.id,
      originalIngredientId: dto.original_ingredient_id || dto.id,
      isComponentWithdraw: dto.component_withdraw,
      isHardener: dto.hardener,
      isPowder: dto.powder,
      isSpecialIngredient: dto.special_ingredient,
      isThinner: dto.thinner,
      name: dto.name,
      thumbnail: dto.thumbnail,
      amountRaw: {
        value: dto.amount_raw.value,
        ascending: dto.amount_raw.ascending,
        min: dto.amount_raw.min || dto.amount_raw.value,
        max: dto.amount_raw.max || dto.amount_raw.value,
      },
    };
  }
}
