import { AddMixtureLayerIngredientsFormDto } from '../../dtos/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.dto';
import { AddMixtureLayerIngredientsFormModel } from '../../models/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.model';

export class AddMixtureLayerIngredientsFormDtoCreator {
  public static create(model: AddMixtureLayerIngredientsFormModel): AddMixtureLayerIngredientsFormDto {
    return {
      dosed_amount: model.dosedAmount,
      id: model.id,
      left_amount: model.leftAmount,
      recipe_amount: model.recipeAmount,
      alternatives: model.alternatives,
    };
  }
}
