import { MixtureAddonSourceDetailsDto } from '../../dtos/mixture-addon-source-details/mixture-addon-source-details.dto';
import { MixtureOwnRecipeSourceDetailsDto } from '../../dtos/mixture-own-recipe-source-details/mixture-own-recipe-source-details.dto';
import { MixtureRecipeSourceDetailsDto } from '../../dtos/mixture-recipe-source-details/mixture-recipe-source-details.dto';
import { MixtureSourceDetailsDto } from '../../dtos/mixture-source-details/mixture-source-details.dto';
import { MixtureSourceTypeEnum } from '../../enums/mixture-source-type/mixture-source-type.enum';
import { MixtureSourceDetailsForMixtureSourceTypeModel } from '../../models/mixture-source-details-for-mixture-source-type/mixture-source-details-for-mixture-source-type.model';
import { MixtureSourceDetailsModel } from '../../models/mixture-source-details/mixture-source-details.model';
import { MixtureAddonSourceDetailsCreator } from '../mixture-addon-source-details/mixture-addon-source-details.creator';
import { MixtureOwnRecipeSourceDetailsCreator } from '../mixture-own-recipe-source-details/mixture-own-recipe-source-details.creator';
import { MixtureRecipeSourceDetailsCreator } from '../mixture-recipe-source-details/mixture-recipe-source-details.creator';
import { MixtureSourceDetailsAlertDto } from '../../dtos/mixture-source-details-alert/mixture-source-details-alert.dto';
import { MixtureSourceDetailsAlertCreator } from '../mixture-source-details-alert/mixture-source-details-alert.creator';

export class MixtureSourceDetailsCreator {
  public static create(dto: MixtureSourceDetailsDto): MixtureSourceDetailsModel {
    return {
      source: {
        type: dto.source.type,
        id: dto.source.id,
      },
      alerts: dto.alerts.map((alert: MixtureSourceDetailsAlertDto) => MixtureSourceDetailsAlertCreator.create(alert)),
      details: MixtureSourceDetailsCreator.createDetails(dto),
      additionalData: {
        colorboxId: dto.additional_data?.colorbox_id || null,
        colorSystemId: dto.additional_data?.color_system_id || null,
        flashcard: dto.additional_data?.flashcard || null,
      },
    };
  }

  private static createDetails(dto: MixtureSourceDetailsDto): MixtureSourceDetailsForMixtureSourceTypeModel {
    switch (dto.source.type) {
      case MixtureSourceTypeEnum.recipe:
      case MixtureSourceTypeEnum.correction:
        return MixtureRecipeSourceDetailsCreator.create(dto.details as MixtureRecipeSourceDetailsDto);
      case MixtureSourceTypeEnum.ownRecipe:
        return MixtureOwnRecipeSourceDetailsCreator.create(dto.details as MixtureOwnRecipeSourceDetailsDto);
      default:
        return MixtureAddonSourceDetailsCreator.create(dto.details as MixtureAddonSourceDetailsDto);
    }
  }
}
