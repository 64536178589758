import { MixturePricesFiltersDto } from '../../dtos/mixture-prices-filters/mixture-prices-filters.dto';
import { MixturePricesFiltersModel } from '../../models/mixture-prices-filters/mixture-prices-filters.model';
import { IngredientToReplaceDtoCreator } from '../ingredient-to-replace-dto/ingredient-to-replace-dto.creator';

export class MixturePricesFiltersDtoCreator {
  public static create(model: MixturePricesFiltersModel): MixturePricesFiltersDto {
    return {
      amount: +model.amount,
      unit: model.unit,
      layer_number: model.layerNumber,
      ...(model.isAmountWithAddons !== undefined && { amount_with_addons: Number(model.isAmountWithAddons) }),
      ...(model.isHardener !== undefined && { hardener: Number(model.isHardener) }),
      ...(model.isRfu !== undefined && { rfu: Number(model.isRfu) }),
      ...(model.isThinner !== undefined && { thinner: Number(model.isThinner) }),
      ...(model.ingredientsToReplace?.length && {
        ingredients_to_replace: IngredientToReplaceDtoCreator.getSerializedIngredientsToReplace(model.ingredientsToReplace),
      }),
    };
  }
}
